var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.notification.message)?_c('b-alert',{staticClass:"mb-3",attrs:{"variant":_vm.notification.type,"dismissible":"","show":""},on:{"dismissed":function($event){return _vm.$store.dispatch('notification/clear')}}},[_vm._v(_vm._s(_vm.notification.message))]):_vm._e(),_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nama")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['name'],
                    },attrs:{"id":"name","type":"text","placeholder":"Nama"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.errors['name'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["name"]))])]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Type")]),_c('b-form-select',{staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['type'],
                    },attrs:{"options":_vm.types},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"disabled":""},domProps:{"value":''}},[_vm._v("Pilih Type")])]},proxy:true},{key:"option",fn:function(ref){
                    var option = ref.option;
return [_c('span',[_vm._v(_vm._s(option))])]}}]),model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),(_vm.errors['name'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["name"]))])]):_vm._e()],1)]),_c('div',{staticClass:"col-md-12"},[_c('table',{staticClass:"table table-bordered"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Gambar")]),_c('th',[_vm._v("Deskripsi")])])]),_c('tbody',[_vm._l((_vm.form.highlights),function(highlight,index){return _c('tr',{key:index},[_c('td',[_c('input',{staticClass:"form-control",class:{
                            'is-invalid': _vm.errors['image'],
                          },attrs:{"type":"file","placeholder":"image"},on:{"change":function($event){return _vm.uploadImage($event, index)}}}),_c('img',{staticClass:"mb-3",attrs:{"src":_vm._f("media")(highlight.imageUrl),"width":"300","alt":""}})]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(highlight.description),expression:"highlight.description"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.errors['description'],
                          },attrs:{"type":"text","placeholder":"Deskripsi"},domProps:{"value":(highlight.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(highlight, "description", $event.target.value)}}}),(_vm.errors['description'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["description"]))])]):_vm._e()]),_c('td',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.form.highlights.splice(index, 1)}}},[_vm._v(" Hapus ")])])])}),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.form.highlights.push({
                              imageUrl: '',
                              description: '',
                            })}}},[_vm._v(" Tambah ")])])])],2)])])]),_c('button',{staticClass:"btn btn-primary mt-4 form-control",attrs:{"disabled":_vm.loading,"type":"submit"}},[(!_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.title))]):_c('div',{staticClass:"spinner-border text-light spinner-border-sm",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }